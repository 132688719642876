import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/cmdnewpic.jpg';
import blogd2 from '../../assets/images/about-4.jpg';
// import blogd3 from '../../assets/images/2022/nurse-ndidi-retire/3.jpg';
// import blogd4 from '../../assets/images/2022/nurse-ndidi-retire/4.jpg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  Prof. Wasiu Lanre Adeyemo (FAS) Chief Medical Director, Lagos
                  University Teaching Hospital has been nominated as one of the
                  most outstanding CMDs in Nigeria!
                </h2>
                <div className='blog-one__image'>
                  <img src={blogd2} alt='' />
                </div>
                <h3 style={{ marginTop: 30, fontStyle: 'italic' }}>
                  My Vision is to Transform LUTH <br />
                  from Tertiary to Quaternary Hospital" – Prof. Adeyemo
                </h3>
                <span style={{ marginBottom: 30, fontStyle: 'italic' }}>
                  By Nnamdi Ojiego
                  <br /> Published on [September 7, 2024]
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Professor Wasiu Lanre Adeyemo, the Chief Medical Director
                  (CMD) of Lagos University Teaching Hospital (LUTH), has shared
                  his ambitious vision to transform LUTH from a tertiary to a
                  quaternary health facility. In an exclusive interview, the
                  renowned Professor of Oral and Maxillofacial Surgery discusses
                  plans for advanced medical services, a permanent cure for
                  sickle cell anemia, and efforts to curb the brain drain, also
                  known as the "Japa" syndrome.
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd1} height={370} />
                  </div>
                  <div
                    className='col-lg-6'
                    style={{ textAlign: 'left', alignItems: 'right' }}
                  >
                    {/* <img src={blogd3} height={370}/> */}
                    <p>
                      <strong>Background and Career Journey</strong>
                      <br />
                      Prof. Adeyemo detailed his humble beginnings, stating, “I
                      didn’t know what I wanted to be until I got to secondary
                      school." After completing his Dentistry studies at the
                      University of Ibadan, he pursued specialization in Oral
                      and Maxillofacial surgery at LUTH, where he has now spent
                      over 20 years. He has played a pivotal role in
                      transforming the hospital, including launching LUTH’s
                      first International Conference in 2020.
                      <br />
                      <br />
                      <strong>Research, Training, and Innovations</strong>
                      <br />
                      Under Prof. Adeyemo’s leadership, LUTH has made
                      significant strides in research and capacity building. "We
                      bring in researchers and experts from around the world to
                      present on relevant topics,” he explained, highlighting
                      plans to further expand research initiatives beyond
                      resident doctors and consultants.
                    </p>
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Nurse Ndidi Enuwa Retires */}
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  <strong>
                    Nuclear Medicine and Cardiac Care Advancements
                  </strong>
                  <br />
                  Prof. Adeyemo also emphasized LUTH’s state-of-the-art cancer
                  center, which has treated over 11,000 patients since 2019. The
                  government has approved funds for a Nuclear Medicine Center to
                  enhance diagnosis and patient care. Additionally, a cardiac
                  catheterization lab is in the works, with ₦1.5 billion
                  allocated for its establishment.
                  <br />
                  <br />
                  <strong>Plans for Geriatrics and Expanded Facilities</strong>
                  <br />
                  With the growing population of Lagos, Prof. Adeyemo
                  underscored the need for expanded healthcare services. A new
                  252-room outpatient clinic is being constructed, along with a
                  skywalk connecting it to the main hospital. Plans for
                  geriatric care, including a four-story facility, and a
                  specialized DOTS center for tuberculosis patients are also
                  underway.
                  <br />
                  <br />
                  <strong>
                    Looking Ahead: Quaternary Care and Stem Cell Transplant
                  </strong>
                  <br />
                  As part of his vision, Prof. Adeyemo aims to establish LUTH as
                  a leader in quaternary care. Collaborations with private
                  hospitals, the Sickle Cell Foundation, and international
                  institutions are key components of this strategy. Notably,
                  LUTH is set to perform its first stem cell transplant for
                  sickle cell patients in collaboration with Vanderbilt Medical
                  University and the Lagos State government by the end of
                  September.
                  <br />
                  <br />
                  In his concluding remarks, Prof. Adeyemo affirmed his
                  commitment to leaving LUTH better than he met it, with a firm
                  focus on quaternary care and cutting-edge medical services.
                  <br />
                  Excerpts from the Vanguard interview highlighting the CMD's
                  exceptional leadership and dedication to excellence in
                  healthcare delivery.
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <a href='https://www.vanguardngr.com/2024/09/special-focus-on-5-most-outstanding-chief-medical-directors-in-nigeria-1/'>
                      Click Here to Read More{' '}
                    </a>
                  </div>
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Baale of Mashewele community, community elder, HOD of
                  Community Health Dept Prof Ogunnowo, Dr Roberts ,Mushin LGA
                  Health Educator Mrs Akinterinwa and Residents in Community
                  Health.
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Other pictures as found below:
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Fig 1: Residents at the department before the program
                  <br />
                  Fig 2: Dr Roberts and Dr Balogun Consultants at Dept of Comm
                  Health at the Outreach.
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Residents dispensing medications in advance for the outreach
                </span>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Community Health Department</a>
                </p>
              </div> */}
                {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='LAGOS UNIVERSITY TEACHING HOSPITAL | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
